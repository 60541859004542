$(function() {
  const btn_filter = $('#departement-filter .btn');
  const cards = $('.card-grid .type-agency');

  $(document).on('click', '#departement-filter .dropdown-item', (item) => {
    item.preventDefault();
    const active_filter = item.currentTarget.dataset.departementFilter;

    btn_filter[0].innerText = item.currentTarget.innerText;

    cards.each( (index, element) => {
      element.classList.remove('d-none');

      if( active_filter != "0" ) {
        if( element.getAttribute('data-departement') != active_filter ) {
          element.classList.add('d-none');
        }
      }
    });
  });
});