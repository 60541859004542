const $window = $(window);
const decalTop = $('.page-template-template-contact .listing-header-content');
const contactForm = $('.page-template-template-contact .contact-form');

let contactPosition = () => {
  if (window.matchMedia("(min-width: 1200px)").matches) {
    const decalTopHeight = (decalTop.height() + 70) * -1;
    contactForm.css('--decal-desk', decalTopHeight+'px');
  }
};

$window.on('load resize', () => {
	contactPosition();
});