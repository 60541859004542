/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';
import EffectCarousel from './effect-carousel';

/* Slider home model */
var models_swiper = new Swiper(".models-swiper", {
  slidesPerView: 1.2,
  spaceBetween: 10,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    630: {
      spaceBetween: 20,
      slidesPerView: 2.2,
    },
    992: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
  },
});

/* Slider news */
var news_swiper = new Swiper(".news-swiper", {
  slidesPerView: 1.2,
  spaceBetween: 10,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    560: {
      spaceBetween: 20,
      slidesPerView: 2.2,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

/* Slider réalisations home */
var achievements_swiper = new Swiper(".achievements-swiper", {
  modules: [EffectCarousel],
  effect: 'carousel',
  carouselEffect: {
    scaleStep: 0.1,
    sideSlides: 2,
  },
  grabCursor: true,
  loop: true,
  loopAdditionalSlides: 1,
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/* Slider single réalisation */
var achievement_swiper = new Swiper(".achievement-swiper", {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: false,
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    576: {
      spaceBetween: 30,
    },
  },
});

/* Slider autres réalisations */
var others_achievement_swiper = new Swiper(".others-achievements-swiper", {
  slidesPerView: 1.2,
  spaceBetween: 10,
  loop: true,
  breakpoints: {
    560: {
      spaceBetween: 20,
      slidesPerView: 2.2,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

/* Slider plan SEO */
var planseo_swiper = new Swiper(".plan-seo-swiper", {
  slidesPerView: 1,
  spaceBetween: 15,
  loop: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    1400: {
      spaceBetween: 26,
    },
  },
});


/* eslint-enable no-unused-vars */