var hamburger = $('.hamburger');
var offcanvas = $('#offcanvas-menu');
var header = $('.header');
var body = $('body');

hamburger.on('click', () => {
	body.addClass('menu-open');
  hamburger.toggleClass('is-active');
  header.toggleClass('mobile-nav-open');
  offcanvas.toggleClass('show');
});

offcanvas.on('hidden.bs.offcanvas', () => {
  hamburger.removeClass('is-active');
  header.removeClass('mobile-nav-open');
  body.removeClass('menu-open');
});