$(function() {
  const show_map = $('#show-map');
  const show_list = $('#show-list');
  const listing_ads = $('.listing-ads');
  const map_list_grid = $('.map-list-grid');

  $(document).on('click','#show-map',() => {
    show_map.addClass('btn-primary');
    show_list.removeClass('btn-primary');
    listing_ads.addClass('showing-map');
    map_list_grid.addClass('showing-map');
  });

  $(document).on('click','#show-list',() => {
    show_list.addClass('btn-primary');
    show_map.removeClass('btn-primary');
    listing_ads.removeClass('showing-map');
    map_list_grid.removeClass('showing-map');
  });
});