var filterBtnAll = document.querySelectorAll('.sf-field-submit input');

if(filterBtnAll) {
  filterBtnAll.forEach( filterBtn => {
    filterBtn.classList.add('btn', 'btn-secondary');
  });
}

var filterDropdownAll = document.querySelectorAll('[data-sf-field-input-type="checkbox"] h4');

if(filterDropdownAll) {
  filterDropdownAll.forEach( filterDropdown => {
    filterDropdown.classList.add('dropdown-toggle');

    filterDropdown.addEventListener('click', () => {
      filterDropdown.classList.toggle('show');
    });
  });
}
